.send-form-wrapper {
  position: relative;
  width: 100vw;
  height: auto;
  border-radius: 20px;
  margin-top: 0 !important;
  max-height: none !important;
  transition-delay: 0s !important;
}

.send-form-wrapper::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  border: 1px solid #fff;
  mask: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
}

@media screen and (max-width: 500px) {
  .send-form-wrapper {
    height: auto !important;
    padding-bottom: 40px;
  }
}

.send-page-container .header,
.mainSend .header {
  width: calc(100% + 80px);
  margin: -0.5rem -40px 0 -40px;
}

.send-page-container .header-wallet-button {
  width: 42px;
  height: 42px;
}

.send-page-container .header-wallet-button .header-mobile-button-img-1 {
  width: 20px;
  height: 20px;
}

.send-page-container .header-wallet-button .header-mobile-button-img-2 {
  top: 8px;
  right: 7px;
}

.disabled-link {
  pointer-events: none;
  opacity: 0.5;
}

.containerGradientDiv {
  width: 60vw;
  height: 50vh;
  background-color: linear-gradient(#16138c, #16138c10);
  position: absolute;
  z-index: 1000;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: r#e2e2e2 !important;
  opacity: 1;
  /* Firefox */
}

.select-source-modal-mt .simplebar-scrollbar::before {
  background-color: #807fb9 !important;
  opacity: 1 !important;
  width: 4px !important;
  top: 4px !important;
  bottom: 4px !important;
  left: 50%;
  transform: translateX(-50%);
}

.select-source-modal-mt .simplebar-vertical {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 25px !important;
  display: flex;
  justify-content: center;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #e2e2e2 !important;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #e2e2e2 !important;
}

.minimum-amount-top {
  margin-top: 8px;
}

.minimum-amount-bottom {
  margin-bottom: 32px;
}

.minimum-amount-bottom-small {
  margin-top: 8px;
  margin-bottom: 16px;
}

.input::placeholder {
  color: #e2e2e2 !important;
  opacity: 1;
}

.accordion-button {
  background-color: #00000040;
  color: white;
  border-radius: 10px;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.text {
  color: #e2e2e2;
  font-size: 1rem;
}

.networkSelectBtn {
  transition: all 600 ease-in-out;
}

.inputGroup {
  /*height: 5rem;*/
  transition: height 300ms ease-out;
  font-size: 1rem;
}

.coinInput {
  background-color: #ffffff20;
  margin-top: 0px;
  margin-bottom: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  width: 100%;
}

.coinInput::placeholder {
  color: #a39dc7;
}

.coin-input-group {
  gap: 8px;
}

.coinInputDiv {
  flex: 2;
  display: flex;
  position: relative;
  align-items: center;
  height: 4rem;
  cursor: pointer;
}

.coinInputDiv input {
  padding: 18px 12px 18px 16px;
}

.you-receive-text-wrapper {
  margin-bottom: 16px;
}

.retrobridge-fee-wrapper {
  margin-bottom: 8px;
}

.select-address-text {
  margin-bottom: 16px;
}

.receive-amount {
  margin-bottom: 8px;
}

.maxBtn:hover {
  color: #75b082;
  transition: all 0.2s ease-out;
}

.maxBtn {
  position: absolute;
  align-self: center;
  right: 12px;
  color: #a1f2b3;
}

.select-receiving-wallet-wrapper {
  gap: 8px;
}

.rightWalletBtnDiv {
  position: relative;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: all 300 ease;
  overflow: hidden;
  border-width: 0px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;

  padding: 19.5px 0;
}

.leftWalletBtnDiv {
  position: relative;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  width: 50%;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  border-width: 0px;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #fff;
}

.leftWalletBtnDiv::before,
.rightWalletBtnDiv::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(94.33deg, #9f7aec 7.8%, #775dff 103.43%);
  box-shadow: 0px 0px 12.1px 0px #ffffffb8 inset;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.leftWalletBtnDiv.active::before,
.rightWalletBtnDiv.active::before {
  opacity: 1;
}

.leftWalletBtnDiv_connected {
  justify-content: flex-start;
  padding: 14px 24px;
}

.connect-starknet-btn {
  display: block;
}

.connect-starknet-btn.active {
  background: linear-gradient(94.33deg, #9f7aec 7.8%, #775dff 103.43%);
  box-shadow:
    0px -1px 6px 0px #ffffffcf inset,
    0px 2px 4px 0px #ffeec159 inset;
  color: white;
}

.leftWalletBtnDiv .address {
  display: flex;
  align-items: flex-start;
}

.gradient {
  background: linear-gradient(94.33deg, #9f7aec 7.8%, #775dff 103.43%);
}

.btn-wallet {
  background-color: #ffffff20;
  color: white;
  width: 49%;
}

.blur {
  backdrop-filter: blur(10px);
}

.headerRight {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  font-size: 1rem;
  background: linear-gradient(
      186.06deg,
      #ffffff20 -164.7%,
      rgba(255, 255, 255, 0) 164.57%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06));
  border: 1px solid #ffffff50;
  width: 469px;
  height: 50px;
  padding-left: 50px;
  gap: 40px;
}

@keyframes opacityIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.header-account-section {
  width: 200px;
  height: 50px;
  padding: 15px 38px 15px 30px;
}

.header-account-section.with-history {
  width: 164px;
  padding: 15px 20px;
}

.account-section-bg {
  background: linear-gradient(
      186.06deg,
      #ffffff20 -164.7%,
      rgba(255, 255, 255, 0) 164.57%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06));
  border: 1px solid #ffffff50;
}

.header-section-border {
  border: 1px rgba(255, 255, 255, 0.2) solid;
}

.connectModalModalBody {
  width: 492px !important;

  padding: 0px;

  margin-inline: auto;
}

@media (max-width: 500px) {
  .connectModalModalBody {
    width: 100vw !important;
  }
}

.account-text {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}

.headerBtn {
  box-shadow: inset white 0px 0px 5px;
  height: 50px;
  width: 159px;
  background: radial-gradient(
    circle,
    rgba(123, 73, 244, 1) 34%,
    rgba(143, 124, 255, 1) 86%,
    rgba(227, 196, 255, 1) 100%
  );
  border-radius: 2rem;
  color: white;
  margin-left: -1.5rem;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  border: 0.2px solid #ffffff70;
  text-decoration: none;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.headerBtn:hover {
  color: #ffffff !important;
  box-shadow:
    0px 0px 30px 4px #ffffff50,
    0px -1px 6px 0px #ffffffcf inset,
    0px 2px 4px 0px #ffeec159 inset !important;
}

.connectModalItem {
  z-index: 100;

  width: 100%;
  height: 60px;
  padding: 12px 12px 12px 16px;
  border-radius: 12px;
  background: #ffffff1a;
  margin-bottom: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.connectModalItem:hover {
  background-color: #ffffff40;
}

.connectNetworkSpan {
  color: white;
  letter-spacing: 0em;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
}

._capitalized {
  text-transform: capitalize;
}

.connectModalIconContainer {
  margin-right: 12px;
  border-radius: 15px;
}

.connectModalTitle {
  margin-bottom: 40px;
  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.connectFormContainer {
  /* margin-top: 266px; */
  padding: 59px 43px 65px 43px;
  position: relative;
  z-index: 100;
  width: 492px;
  min-height: 335px;
  background: linear-gradient(180deg, #5a43b4 -27.02%, #0d0821 109.79%),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    );
}

.connectFormContainer.small {
  min-height: 0 !important;
  padding: 59px 43px 31px 43px;
}

@media (max-width: 500px) {
  .connectFormContainer {
    width: 100%;
  }
}

.connectModalCloseBtn {
  top: 29.75px;
  right: 30.75px;
  position: absolute;
}

.blurBtn {
  backdrop-filter: blur(10px);
  color: #ffffff;
  background-color: #ffffff20;
}

.smallCircleBtnCoinIconHidden {
  background-color: transparent;
  margin-right: 10px;
}

.disabled-currency {
  color: rgba(163, 157, 199, 1);
  opacity: 0.8;
  /* filter: grayscale(100%); */
  mix-blend-mode: luminosity;
  pointer-events: none;
}

.coinBtn {
  border-radius: 2rem;
  background-color: #ffffff;
  border-width: 0px;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
  aspect-ratio: 1/1;
  padding: 0;
}

.wallet-icon-size {
  min-width: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.wallet-icon-size img {
  border-radius: 50%;
}

.smSize {
  height: 1.2rem;
  width: 1.2rem;
}

.exchangeImg {
  width: 1rem;
  height: 1.5rem;
}

.exchange-btn-size {
  width: 32px !important;
  height: 32px !important;
}

.btnGradient-circle {
  background: rgb(111, 108, 255);
  background: radial-gradient(
    circle,
    rgba(111, 108, 255, 1) 17%,
    rgba(143, 124, 255, 1) 78%,
    rgba(197, 196, 255, 1) 100%
  );
  border-radius: 2rem;
  color: white;
  font-weight: 700;
  width: 3rem;
}

.btnGradient {
  border: none;
  background: radial-gradient(
    56.49% 491.67% at 49.64% 50%,
    #4d36d9 -2.88%,
    #b3a6ff 81.64%,
    #d8d1ff 100%
  );
  box-shadow:
    0px 0px 0px 0px #ffffff00,
    0px -1px 6px 0px #ffffffcf inset,
    0px 2px 4px 0px #ffeec159 inset;
  border-radius: 2rem;
  color: white;
  font-weight: 600;
  width: 3rem;
  transition: box-shadow 0.3s ease-in-out;
}

.btnGradient:hover {
  color: #ffffff !important;
  box-shadow:
    0px 0px 20px 4px #ffffff30,
    0px -1px 6px 0px #ffffffcf inset,
    0px 2px 4px 0px #ffeec159 inset !important;
}

.btnGradient:disabled {
  background: #a181ff33 !important;
  box-shadow: none !important;
  opacity: 0.5;
  pointer-events: none;
}

.btnSecondary {
  border-radius: 2rem;
  color: white;
  font-weight: 600;
  padding-top: 14px;
  padding-bottom: 14px;
  /*height: 3rem;*/
  background-color: #a181ff33;
  border-width: 0px;
}

.btnSecondary:hover {
  border-width: 0px;
  background-color: #a181ff33;
  font-size: 14px;
  transition: all 0.4s ease-in-out;
}

.select-source-search-icon {
  position: absolute;
  align-self: center;
  left: 16px;
  height: 20px;
  width: 20px;
}

.roundBtn,
.selected-network {
  height: 32px;
  width: 32px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-left: 4px;
  border-width: 0px;
  color: white;
  font-size: 10px;
}

.selected-network {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.roundBtn {
  background-color: rgba(255, 255, 255, 0.2);
}

.roundBtn:hover,
.selected-network:hover {
  box-shadow: #ffffff 0px 0px 2px;
}

.roundBtn:active,
.selected-network:active {
  box-shadow: #ffffff 0px 0px 6px;
}

.selected-network {
  margin: 0;
  padding: 6px;
}

.form-control:focus {
  background-color: #ffffff10;
  color: #ffffff;
}

.input-dropdown-arrow {
  position: absolute;
  top: 20px;
  right: 8px;
}

.btnIcon {
  width: 0.8rem;
  height: 0.8rem;
}

.spacing-left-2 {
  padding-left: 5px;
}

.header-pl {
  padding-block: 15px;
  padding-left: 50px !important;
}

.form-mt {
  margin-top: 24px;
}

.networkSelectBtn-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.network-label {
  font-size: 14px;
}

.networks-list-wrapper {
  padding: 6px 0;
}

.switch-btn-wrapper {
  margin: 16px 0 0 0;
}

.networks-list {
  justify-content: space-between;
  padding: 6px 0;
  gap: 8px;
}

.rounded-circle {
  margin: 0;
}

.amount-send-text-wrapper {
  margin-top: 24px;
  margin-bottom: 8px;
}

.amount-receive-text-wrapper {
  margin-top: 16px;
  margin-bottom: 8px;
}

.min-max-text {
  color: #a39dc7;
  font-weight: bold;
}

.padding {
  padding-inline: 5px;
  width: auto;
  border-radius: 30px;
  animation: fadein 0.5s ease;
}

.size-auto {
  height: auto !important;
}

.font-size-12 {
  font-size: 12px;
  color: #9794af;
}

.font-size-14 {
  font-size: 14px;
}

.padding-6 {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 14px;
  width: auto;
  border-radius: 30px;
}

.networkSmallIcon {
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
}

.blanco {
  color: #a39dc7;
  font-size: 12px;
}

.fz12 {
  font-size: 12px;
}

.error-message {
  color: #ff9eb6;
  font-size: 12px;

  margin-bottom: 0 !important;
}

.with-error {
  margin-top: 0;
}

.address-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 16px;
  right: 12px;
}

.address-error-message {
  margin-top: 8px !important;
  margin-bottom: 0 !important;
}

.walletAddressInput {
  color: #9794af;
  background-color: #ffffff1a;
  border-radius: 15px;
  padding-left: 16px !important;
  padding-right: 47px !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.walletAddressInput::placeholder {
  color: #a39dc7;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.walletAddressInput:focus {
  background-color: #ffffff1a;
  color: #9794af;
}

.transitionWidth {
  transition: width 300ms ease-out;
}

.showInput {
  height: 4rem;
  overflow: hidden;

  transition: height 300ms ease-out;
}

.modal-content {
  background-color: transparent;
  border: none;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.networkListDiv {
  /* height: 330px; */
  color: #ffffff;
  display: flex;
  flex-direction: column;

  margin-top: 24px;
}

.disabled-network-wrapper {
  pointer-events: none;
  justify-content: space-between;
}

.disabled-network-wrapper .disabled-network-description {
  margin: 0;
  padding: 0;
  padding-right: 15px;

  color: rgba(163, 157, 199, 1);

  font-size: 12px;
}

.disabled-network {
  color: rgba(163, 157, 199, 1);
  opacity: 0.8;
  /* filter: grayscale(100%); */
  mix-blend-mode: luminosity;
  pointer-events: none;
}

.disabled-network-circle {
  color: rgba(163, 157, 199, 1);
  filter: grayscale(100%);
  /* mix-blend-mode: luminosity; */
  pointer-events: none;
}

.network-balance-info {
  position: absolute;
  top: 2px;
  right: 28px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #a39dc7;
  z-index: 10;
}

.network-balance-info-currency {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.network-balance-info-currency-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 2px;
  background: #fff;
  border-radius: 100px;
}

.network-balance-info-currency-icon img {
  width: 12.8px;
  height: 12.8px;
  object-fit: contain;
}

.network-balance-info-tooltip-trigger {
  position: relative;
  display: flex;
  justify-content: center;
  width: 12px;
  margin-left: 6px;
  font-size: 8px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  cursor: pointer;
}

.network-balance-info-tooltip-trigger::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.15);
}

.network-balance-info-tooltip {
  position: relative;
  width: 172px;
  padding: 13px 8px 15px 15px;
  border-radius: 12px;
  background-size: 172px 68px;
  z-index: 100000;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.network-balance-info-tooltip-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 68px;
  z-index: -1;
}

.network-balance-info-tooltip img {
  width: 27px;
  height: 16px;
  margin-inline: 4px;
}

.network-balance {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 130px;
  margin: 0 17px 0 auto;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.network-balance-disabled {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: #a39dc7;
}

.network-balance-loading {
  min-width: 60px;
}

.network-balance-loader {
  margin-right: 6px;
}

.network-balance-loading .network-balance-loader {
  margin-right: auto;
}

.networkListDiv:first-child {
  margin-top: 0;
}

.small-checkbox {
  border: 1px #7d7992 solid;
}

.checked {
  background: linear-gradient(#8f7cff, #e5c4ff);
}

.smallCircle {
  background-color: #ffffff;
  width: 6px;
  height: 6px;
  border-radius: 3rem;
}

.smallSquare {
  background-color: #ffffff;
  width: 8px;
  height: 8px;
  border-radius: 1px;
}

.hidden {
  transition: all 0.3s ease;
  height: 0px;
  overflow: hidden;
}

.icon-16 {
  min-width: 16px;
  width: 16px;
  height: 16px;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.btn-20 {
  width: 20px !important;
  height: 20px !important;
}

.btn-padding {
  padding: 6px 12px 6px 6px;
}

.btn-margin-right-6 {
  margin-right: 6px !important;
}

.btn-text-12 {
  font-size: 12px !important;
  font-weight: 500 !important;
}

/*.select-source-modal-mt {*/
/*  margin-top: 20%;*/
/*}*/

.select-source-modal-bottom {
  overflow: auto;
  scrollbar-color: #807fb9 #ffffff20;
  scrollbar-width: thin !important;
}

/* .select-source-modal-bottom::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 25px;
  background-color: #ffffff20;
}

.select-source-modal-bottom::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background: #807fb9;
  border: 2px solid transparent;
  background-clip: content-box;
} */

.select-source-modal-bottom::-webkit-scrollbar {
  display: none;
}

.select-source-input {
  padding: 14px 12px 14px 52px;
  height: 50px;
}

.select-source-input::placeholder {
  color: #a39dc7;
  font-size: 14px;
  font-weight: 500;
}

.select-source-modal-padding {
  padding: 38px 34px 31px;
}

.notification {
  position: relative;
  max-width: 284px;
  margin-left: auto;
  padding: 16px 15px 16px 22px;
  background-color: rgba(255, 255, 255, 0.1);
  /*background-color: black;*/
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.notification-left {
  display: flex;
}

.notification .text-container {
  display: flex;
  flex-direction: column;
}

.notification-img {
  cursor: pointer;
}

.notification-border {
  height: 100%;
  position: absolute;
  left: 1px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.notification .notification-border {
  border-left: 1px solid #8fffa8;
}

.notification._error .notification-border,
.notification._info .notification-border {
  border-left: 1px solid rgba(255, 158, 182, 1);
}

.send-page-badge {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
  padding: 6px 12px;
  border-radius: 40px;
  background: #d1956a2e;
  border: 0.5px solid #f8cbab63;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #f8cbab;
}

@keyframes slidein {
  from {
    opacity: 0;
    scale: 0;
  }

  to {
    opacity: 1;
    scale: 1;
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    scale: 1;
  }

  to {
    opacity: 0;
    scale: 0;
  }
}

.noHeight {
  height: 0px;
  overflow: hidden;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.cursor {
  cursor: pointer;
}

.netwIcon {
  width: 1rem;
  height: 1rem;
}

.headerBg {
  background: linear-gradient(
      186.06deg,
      #ffffff -164.7%,
      rgba(255, 255, 255, 0) 164.57%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06));
  border: 1px solid;

  border-image-source: linear-gradient(
    186.06deg,
    #ffffff -164.7%,
    rgba(255, 255, 255, 0) 164.57%
  );
}

.padding40 {
  padding-left: 45px;
}

.loaderWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-page-footer {
  margin-top: 36px;
  width: 100%;
}

.wallet-btns-text-size {
  font-size: 14px;
}

.tokens-dropdown-text-size {
  font-size: 12px;
}

@media screen and (max-width: 600px) {
  .coinBtn {
    height: 24px;
    width: 24px;
  }

  .mainSend .coinBtn .networkIcon {
    min-width: 19px;
    width: 19px;
    min-height: 19px;
    height: 19px;
  }
}

@media only screen and (max-width: 480px) {
  .leftWalletBtnDiv {
    padding: 8px 16px 8px 16px;
  }

  .network-balance {
    margin-right: 10px;
  }

  .minimum-amount-bottom {
    margin-top: 8px;
  }

  .switch-btn-wrapper {
    margin: 16px 0;
  }

  .exchange-btn-size {
    width: 32px !important;
    height: 32px !important;
  }

  .coinBtn {
    height: 24px;
    width: 24px;
  }

  .roundBtn.rounded-circle {
    height: 24px;
    width: 24px;
    aspect-ratio: 1/1;
    padding: 0;
  }

  .mainSend .coinBtn .networkIcon {
    min-width: 19px;
    min-height: 19px;
  }

  .networkSelectBtn-text {
    font-size: 12px;
    font-weight: 500;
  }

  .min-max-text {
    font-weight: 500;
    font-size: 12px;
  }

  .connected-wallet-text,
  .wallet-btns-text-size {
    font-size: 12px !important;
  }

  .connected-address {
    font-size: 11px !important;
  }

  .mobile-margin-bottom {
    margin-bottom: 48px;
  }

  .walletAddressInput {
    font-size: 12px;
  }

  .select-source-input {
    margin-top: 17px !important;
    margin-bottom: 24px !important;
  }

  .select-source-modal-padding {
    padding: 42px 20px 31px 20px;
  }

  .send-page-badge {
    border-width: 1px;
  }
}

@media screen and (max-width: 340px) {
  .networks-list {
    gap: 4px;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.accouns-wrapper :last-child {
  border-radius: 2rem;
}

.disabled-wallets-container {
  margin-top: 16px;
}

.disabled-wallets-container .connectModalItem.d-flex.align-items-center,
.disabled-wallets-container .connectModalItem.d-flex.align-items-center:hover {
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.4);
  user-select: none;
}

.disabled-wallets-container
  .connectModalItem.d-flex.align-items-center
  .connectModalIconContainer,
.disabled-wallets-container
  .connectModalItem.d-flex.align-items-center
  .connectModalIconContainer:hover {
  opacity: 0.6;
  filter: grayscale(100%);
}

.disabled-wallets-container
  .connectModalItem.d-flex.align-items-center
  .connectNetworkSpan,
.disabled-wallets-container
  .connectModalItem.d-flex.align-items-center
  .connectNetworkSpan:hover {
  color: rgba(255, 255, 255, 0.4);
}

.disabled-wallets-container .disabled-message {
  font-size: 12px;
  font-weight: 500;
  color: #dad3ff;

  opacity: 0.8;
}

.connected-margin {
  margin-top: 24px !important;
}

.connected-wallet-text {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.connected-address {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
}

.networks-category-text {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: rgba(163, 157, 199, 1);
}

.header-mobile-history {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  height: 50px;
  padding: 0 25px 0 17px;
}

.header-mobile-history img {
  min-width: 18px;
  height: 18px;
}

.header-mobile-history--count {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  background: linear-gradient(180deg, #b9aeff 0%, #cf93ff 100%);
  font-size: 8px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  transform: translate(50%, -50%);
}

@media screen and (max-width: 768px) {
  .send-page-container .header,
  .mainSend .header {
    width: calc(100% + 40px);
    margin: -0.5rem -20px 0 -20px;
  }
}
